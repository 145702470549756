import styles from "./footer.css";

function Footer() {
  return (
    <div className="footer-holder">
      <p>Hey, what are you still doing here?!?</p>
    </div>
  );
}

export default Footer;
