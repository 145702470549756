import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCalculator,
  faLink,
  faPiggyBank,
  faAddressBook,
  faNetworkWired,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

import CalTracker from "../../../assets/images/cal-tracker.png";
import Banksy from "../../../assets/images/banksy.png";
import NetworkBuddy from "../../../assets/images/network-buddy.png";

import styles from "./projects.css";

function Projects() {
  return (
    <div className="projects-container">
      {/* first project */}
      <div className="project">
        <div className="project-text">
          <FontAwesomeIcon
            icon={faAddressBook}
            className="project-icons utensils"
          />
          <FontAwesomeIcon
            icon={faNetworkWired}
            className="project-icons calculator"
          />
          <h2 className="italic">Personal Project</h2>
          <h1>Network Buddy</h1>
          <h5>
            Struggle to remember names and conversation details when networking
            at conferences? Look no further! Beef up your networking game with
            Network Buddy.
          </h5>
          <div className="project-skills">
            <span>Next.js</span>
            <span>PlanetScale</span>
            <span>Tailwind</span>
            <span>tRPC</span>
            <span>Prisma</span>
          </div>
          <div className="project-links">
            <a href="https://network-buddy.vercel.app" target="_blank">
              <FontAwesomeIcon icon={faLink} />
            </a>
            <a
              href="https://github.com/johngaynor/network-buddy"
              target="_blank"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
        <div className="project-img">
          <div className="project-img-filter"></div>
          <img src={NetworkBuddy} alt="image of network buddy site" />
        </div>
      </div>
      {/* second project */}
      <div className="project left">
        <div className="project-text">
          <FontAwesomeIcon icon={faPiggyBank} className="project-icons fish" />
          <h2 className="italic">Passion Project</h2>
          <h1>Banksy</h1>
          <h5 className="text-md xxs:text-md lg:text-xl">
            Welcome to Banksy, your analytical finance tool. Process CSV's into
            statistics to help you understand your spending habits.
          </h5>
          <div className="project-skills">
            <span>Next.js</span>
            <span>Vercel Postgres</span>
            <span>Material UI</span>
            <span>JSON Web Tokens</span>
            <span>Recharts</span>
          </div>
          <div className="project-links">
            <a href="https://banksyy.vercel.app/" target="_blank">
              <FontAwesomeIcon icon={faLink} />
            </a>
            <a href="https://github.com/johngaynor/banksy" target="_blank">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
        <div className="project-img">
          <div className="project-img-filter"></div>
          <img src={Banksy} alt="image of banksy site" />
        </div>
      </div>
      {/* third */}
      <div className="project">
        <div className="project-text">
          <FontAwesomeIcon
            icon={faCalculator}
            className="project-icons hotdog"
          />
          <h2 className="italic">Personal Project</h2>
          <h1>Calorie Tracker</h1>
          <h5>
            Unhappy with the lack of custom functionality on popular calorie
            tracking apps, I decided to make my own. Features include a
            dashboard, calculators, and custom foods/recipes.
          </h5>
          <div className="project-skills">
            <span>React</span>
            <span>Firebase</span>
            <span>CSS</span>
            <span>Bootstrap</span>
            <span>Realtime DB</span>
          </div>
          <div className="project-links">
            <a href="https://calorietracker.johngaynor.dev" target="_blank">
              <FontAwesomeIcon icon={faLink} />
            </a>
            <a
              href="https://github.com/johngaynor/calorie-tracker.git"
              target="_blank"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
        <div className="project-img">
          <div className="project-img-filter"></div>
          <img src={CalTracker} alt="image of calorie tracker site" />
        </div>
      </div>
      <h4>
        Honorable Mentions
        <FontAwesomeIcon icon={faArrowDown} className="mx-2" />
      </h4>
    </div>
  );
}

export default Projects;
