import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCode,
  faCodeBranch,
  faCodeCommit,
  faDumbbell,
  faFileCode,
} from "@fortawesome/free-solid-svg-icons";

import Resume from "../../resume/resume";
import AboutMe from "../aboutMe/aboutMe";
import Projects from "../projects/projects";
import Mentions from "../mentions/mentions";
import Contact from "../contact/contact";
import Footer from "../../footer/footer";
import styles from "./hero.css";

function Hero() {
  const scrollToAbout = () => {
    const about = document.getElementById("about");
    about.scrollIntoView();
  };

  const scrollToProjects = () => {
    const myProjects = document.getElementById("my-projects");
    myProjects.scrollIntoView();
  };

  const scrollToContact = () => {
    const contact = document.getElementById("contact");
    contact.scrollIntoView();
  };

  const extendNav = () => {
    const navClasses = Array.from(
      document.getElementById("nav-bars").classList
    );
    const navClass = document.getElementById("nav-bars");
    if (navClasses.includes("active")) {
      navClass.classList.remove("active");
    } else {
      navClass.classList.add("active");
    }
  };

  return (
    <>
      <div className="hero-container">
        <div className="hero-links d-md-flex d-none">
          <h5 onClick={scrollToProjects}>PROJECTS</h5>
          <h5 onClick={scrollToAbout}>ABOUT</h5>
          <Resume />
          <h5 onClick={scrollToContact}>CONTACT</h5>
        </div>
        <div className="d-block d-md-none">
          <div id="nav-bars" onClick={extendNav}>
            <span className="nav-bar"></span>
            <span className="nav-bar"></span>
            <span className="nav-bar"></span>
            <div className="hamburger-links">
              <h5 onClick={scrollToAbout}>ABOUT</h5>
              <h5 onClick={scrollToProjects}>PROJECTS</h5>
              <h5 onClick={scrollToContact}>CONTACT</h5>
            </div>
          </div>
        </div>
        <FontAwesomeIcon icon={faCode} className="hero-icons closing-tag" />
        <FontAwesomeIcon
          icon={faCodeBranch}
          className="hero-icons code-branch"
        />
        <FontAwesomeIcon
          icon={faCodeCommit}
          className="hero-icons code-commit"
        />
        <FontAwesomeIcon icon={faFileCode} className="hero-icons code-file" />
        <FontAwesomeIcon icon={faDumbbell} className="hero-icons icon-weight" />

        <div className="subtitles">
          <h3>Hey, I'm John Gaynor.</h3>
          <h1 className="subtitle middle">
            My <span>passion</span> is building solutions for websites and
            applications.
          </h1>
          <h1 className="subtitle smaller mb-5 pb-5">
            Oh, did I mention Rubik's cubes and lifting heavy circles off the
            ground?
          </h1>

          <h4>
            My Projects <FontAwesomeIcon icon={faArrowDown} className="mx-2" />
          </h4>
        </div>
      </div>
      <div id="my-projects">
        <Projects />
        <Mentions />
      </div>
      <div id="about">
        <AboutMe />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default Hero;
